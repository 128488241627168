@import "../../theme/variables.scss";

.Dashboard {
  position: relative;
  width: 100%;
  max-width: 1170px;
  //padding-bottom: 250px;

  .dash-head {
    @include XGA {
      background-color: #fff;
      max-width: 708px;
      border-radius: 12px;
      margin: 0 auto;
      margin-bottom: -100px;
      z-index: 2;
      position: inherit;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 22px;
    }

    .welcome {
      color: #fff;
      background-image: url("./welcome-bg.png");
      height: 125px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      gap: 4px;
      border-radius: 12px;
      @include XGA {
        width: 357px;
        border-radius: 12px;
      }

      p {
        margin: 0;
      }

      .name {
        font-size: 30px;
        font-weight: 700;
      }
    }

    .recommend-code{
      color: #001854;
      text-align: center;

      p {
        line-height: 14px;
        @include XGA {
          margin: 8px 0;
         }
      }

      .header {
        font-size: 18px;
        font-weight: 700;
      }

      button {
        color: #FFB500; 
        border: 2px solid #FFB500;
        background-color: transparent;
        border-radius: 35px;
        width: 100%;
        padding: 12px;
        font-weight: 700;

        @include XGA {
          margin-top: 16px;
        }
      }

      @include XGA {
        width: 270px;
      }
    }
  }

  .dash-body {
    color: #001854;
    margin-top: 24px;
    @include XGA {
      padding-top: 136px;
      margin-top: 0;
    }

    .header-container {
      @include XGA {
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .header {
        margin: 0;
        font-size: 18px;
        span {
          font-weight: 700;
        }
      }
    }
    .box-container {
      display: flex;
      flex-direction: column;

      @include XGA {
        justify-content: space-between;
        flex-direction: row;
       }

       @include HD {
        justify-content: space-between;
        flex-direction: row;
       }
    }
    .box {
      background: #F4F5F9;
      border-radius: 12px;
      padding: 18px;
      margin-top: 22px;
      min-width: 256px;

      @include XGA {
        min-width: 196px;
        gap: 16px;
       }

       @include HD {
        min-width: 248px;
       }

      .numbers {
        font-size: 22px;
      }

      .grey-number {
        color: rgba(13, 36, 91, .2)
      }

      p {
        margin: 0;
      }

      p:first-child {
        margin-bottom: 6px;
      }
    }
    .link-statistics {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }

 

  .chart-sumup {
    display: flex;
    flex-wrap: wrap;
  }

  .sumup-box {
    width: 100%;
    height: 70px;
    padding: 10px 16px;
    background-color: $buttonColor;
    color: #fff;
    font-size: 12px;
    border-radius: 12px;
    margin-bottom: 12px;

    @include XGA {
      width: 210px;
      font-size: 14px;
      margin-bottom: 15px;
      margin-right: 20px;
    }

    .sold {
      font-size: 18px;
      font-weight: 700;

      @include XGA {
        font-size: 20px;
      }

      span {
        font-size: 14px;
        font-weight: 400;
      }
    }

    &-employee {
      background-color: $primaryColor;

      .name {
        font-size: 14px;

        @include XGA {
          font-size: 18px;
        }
      }
    }

    .sumup-top {
      display: flex;
      justify-content: space-between;
      font-size: 18px;

      .name {
        display: flex;
        align-items: center;

        .colored-circle {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-right: 12px;
          //margin-top: 7px;
        }
      }
    }

    .sumup-bar {
      width: 100%;
      height: 12px;
      background-color: rgba(255,255,255, .7);
      border-radius: 12px;

      .sumup-bar-percentage {
        margin-top: 6px;
        height: 100%;
        border-radius: 12px;
        background-color: #fff;
      }
    }
  }

  .chart-notice {
    text-align: center;
    font-size: .9em;
    margin-bottom: 0;
  }
}
