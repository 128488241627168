@import "../../theme/variables.scss";

.Statistics {
  position: relative;
  width: 100%;
  max-width: 1170px;
  //padding-bottom: 250px;

  .dash-head {
    .address {
      font-size: 10px;
      color: $primaryColor;

      @include XGA {
        font-size: 16px;
      }
    }

    .sales-point {
      margin: 0px 0 20px;
      font-size: 22px;
      font-weight: 700;
      color: $primaryColor;

      @include XGA {
        font-size: 30px;
      }

      span {
        color: #000;
      }
    }
  }

  .chart-head {
    margin-bottom: 20px;

    .chart-options {
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid $primaryColor;

      @include XGA {
        display: flex;
        justify-content: space-between;
      }

      .data-options {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .DropDownTabs {
          min-width: 135px;

          @include XGA {
            min-width: 190px;
          }
        }

      }
      
      .date-options {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 12px;
        //padding-top: 12px;
        //border-top: 1px solid $primaryColor;
        
        @include XGA {
          margin-top: 0;
          padding-top: 0;
          border: 0;
        }

        .DateSelect {
          display: flex;
          align-items: center;
          font-weight: 700;
          margin-left: 20px;

          .date-range {
            text-transform: capitalize;
          }

          .prev-date, .next-date {
            height: 28px;
            cursor: pointer;
          }
        }
      }

      .chart-checkbox {
        margin-bottom: 0;
        font-size: 12px;

        label {
          margin-bottom: 0;
        }

        @include XGA {
          margin-left: 24px;
          font-size: 14px;
        }
      }
    }

    .sold-devices {
      margin: 15px auto;
      color: $primaryColor;
      text-align: center;

      @include XGA {
        font-size: 18px;

        span {
          font-size: .8em;
        }
      }
    }
  }

  .chart-bottom {
    margin-top: 25px;

    .chart-bottom-options {

      @include XGA {
        display: flex;
      }

      .chart-checkbox {
        margin-right: 15px;
      }
    }

    h3 {
      font-size: 14px;
      margin-top: .5em;
      margin-bottom: 0;
      color: $primaryColor;

      @include XGA {
        font-size: 18px;
      }
    }

    p {
      margin-top: 0;
      font-size: 12px;
    }
  }

  .chart-employees {
    display: flex;
    flex-wrap: wrap;
    margin-top: 35px;

    @include XGA {
      justify-content: space-between;
    }
  }

  .chart-employees-top {
    @include XGA {
      display: flex;
      justify-content: space-between;
    }

    .search-input {
      margin-top: 10px;
      width: 100%;
      max-width: 330px;
      background-image: url(./search-icon.svg);
      background-position: 95% 14px;
      background-repeat: no-repeat;

      input {
        background-color: transparent;
        width: 100%;
        height: 40px;
        font-size: 14px;
        border-radius: 12px;
      }
    }
  }

  .sumup-box {
    width: 100%;
    height: 88px;
    padding: 10px 16px;
    background-color: #F4F5F9;
    color: $primaryColor;
    font-size: 12px;
    border-radius: 12px;
    margin-bottom: 12px;

    @include XGA {
      width: 32%;
      font-size: 14px;
      margin-bottom: 20px;
      //margin-right: 20px;
    }

    &.inactive {
      opacity: .5;
    }

    .sold {
      display: flex;
      align-items: center;
      font-size: 12px;

      .chart-line {
        position: relative;
        width: 20px;
        height: 1px;
        margin-right: 8px;
        background-color: #F4F5F9;

        .chart-line-point {
          position: absolute;
          left: 7px;
          top: -3px;
          width: 7px;
          height: 7px;
          background-color: #F4F5F9;
          border: 1px solid #F4F5F9;
          border-radius: 50%;
        }
      }
    }

    .sumup-top {
      display: flex;
      justify-content: space-between;
      width: 100%;
      cursor: pointer;

      .name {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 12px;

        .colored-circle {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-right: 12px;
          //margin-top: 7px;
        }

        .employee-checkmark {
          position: relative;
          width: 18px;
          height: 18px;
          border: solid 1px $primaryColor;
          border-radius: 3px;
          margin-right: 10px;
      
          &:after {
            display: none;
            content: "";
            position: absolute;
            left: 3px;
            top: 3px;
            width: 10px;
            height: 10px;
            background-color: $primaryColor;
            border-radius: 2px;
          }

          &.active {
            &:after {
              display: block;
            }
          }
        }
      }
    }

    a {
      position: relative;
      display: inline-block;
      text-decoration: none;
      font-size: 12px;
      font-weight: 400;

      &:after, &:before {
        content: "";
        position: absolute;
        top: 7px;
        right: -9px;
        width: 4px;
        height: 4px;
        border-top: 1px solid $primaryColor;
        border-left: 1px solid $primaryColor;
        transform: rotate(135deg);
      }
    }

    .sumup-address {
      margin: 10px 0 5px;
      font-size: 12px;
    }

    .sumup-bar {
      width: 100%;
      height: 12px;
      background-color: rgba(255,255,255, .7);
      border-radius: 12px;

      .sumup-bar-percentage {
        margin-top: 6px;
        height: 100%;
        border-radius: 12px;
        background-color: #fff;
      }
    }
  }

  .chart-notice {
    text-align: center;
    font-size: .9em;
    margin-top: 30px;
    margin-bottom: 0;
  }
}
