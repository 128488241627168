@import "../../theme/variables.scss";

.Profile {
  position: relative;
  width: 100%;
  max-width: 1170px;
  //padding-bottom: 250px;

  .edit-box {
    @include XGA { 
      padding: 36px;
    }
  }

  .profile-body {
    color: #001854;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 24px;
    @include XGA {
      flex-direction: row;
      align-items: flex-start;
    }

    .img-container {

      @include XGA { 
        margin-right: 48px;
      }
      img {
        border-radius: 50%;
        width: 120px;
        height: 120px;
        margin-top: 10px;
        object-fit: cover;

        @include XGA { 
          width: 202px;
          height: 202px;
        }
      }
      .change-pic {
        color: #F8B40E;
        text-decoration: underline;
        font-size: 14px;
        text-align: center;
        font-weight: 700;
        cursor: pointer;
      }
    }
    .text-container {
      text-align: center;
      
      @include XGA {
        text-align: left;
        br {
          display: none;
        }
      }

      h2 {
        font-size: 20px;
        margin: 0 0 8px 0;
        @include XGA {
          font-size: 30px;
          margin: 0 0 28px 0;
        }
      }

      .text-data {
        p {
          margin: 6px auto;
          @include XGA {
            font-size: 18px;
          }
        }
      }

      .edit-btn {
        margin-top: 12px;
        color: #F9B40E;
        font-weight: 700;
        text-decoration: underline;
        font-size: 14px;
        background-color: none;
        cursor: pointer;
      }
    }

    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      @include XGA {
        flex-direction: row;
      }

      .img-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
          color: #817E7E;
          font-size: 10px;
          text-align: center;
          margin-top: 4px;
        }
      }
    }

    .edit-container {
      width: 100%;
      
      @include XGA {
        br {
          display: none;
        }
      }

      h2 {
        font-size: 20px;
        @include XGA {
          font-size: 30px;
          margin: 0 0 28px 0;
        }
      }
      
      label {
        font-weight: 700;
        margin-bottom: 4px;
      }

      input, textarea {
        margin-top: 4px;
      }

      .data-edit {

        div {
          margin-top: 24px;
          @include XGA {
            margin-top: 0;
          }
        }

        @include XGA {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-column-gap: 38px;
          grid-row-gap: 18px;
        }
      }
      
      .about-me-container {
        position: relative;
        margin-top: 24px;
        @include XGA {
          margin-top: 18px;
        }
      }

      .about-me {
        height: 138px;
        border-radius: 4px;
        border: solid 1px #000;
        padding: 11px 15px;
        outline: none;
        font-size: 16px;
        font-family: "Titillium Web", sans-serif;
        margin-bottom: 5px;
        display: block;
        width: 100%;
        background-color: #f4f5f9;
        -webkit-appearance: none;
        @include XGA {
          max-width: 847px;
        }
      }

      .coutner {
        position: absolute;
        bottom: 6px;
        left: 12px;
      }

      button {
        margin-top: 24px;
        margin-left: 0;
        background-color: #F9B40E;
        color: black;
        border: none;
        width: 237px;
        height: 37px;
        border-radius: 50px;
        @include XGA {
          margin-top: 36px;
        }
      }
    }
  }

}
