@import "../../theme/variables.scss";

.Header {
	margin: 0;
	padding: 0;
	z-index: 950;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  .color-line {
    top: 0;
    left: 0;
    width: 100%;
    height: 32px;
    border: 0;
    display: block;
  }

  .ButtonBack {
    top: 12px;
  }

  &.fixed {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top:-28px;
    background: #F4F5F9;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  }

  &.fixed .header-inner {
    height: 100%;
    height: 56px;
  }

  @include HD {
    height: 108px;
    background: #F4F5F9;
    box-shadow: 0px -3px 50px rgba(45, 45, 45, 0.15);
  }

  .header-inner {
    position: relative;
    width: 90%;
    margin: 0 5%;
    max-width: 1170px;
    height: 100%;
    padding-top: 5px;
    height: 56px;

    @include XGA {
      padding-top: 5px;
    }
    
    @include HD {
      margin: 0 auto;
      padding-left: 35px;
    }

    &.with-info {
      margin-top: 0px;
    }
  }

  .nav-trigger {
    z-index: 100;
    position: absolute;
    top: 12px;
    right: 0;
    width: 32px;
    height: 32px;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.04);

    @include HD {
      top: 16px;
      //right: 25px;
      display: none;
    }
  }

  .profile-container {
    z-index: 100;
    position: absolute;
    top: 12px;
    right: 0;
    display: none;
    text-align: right;
    min-width: 165px;

    @include HD {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .name {
      font-size: 20px;
      font-weight: 700;
    }

    .title {
      color: $primaryColor;
    }

    .profile-pic {
      width: 36px;
      height: 36px;
      object-fit: cover;
      border-radius: 50%;

      @include HD {
        margin-top: 4px;
      }
    }
  }

	.bell-icon {
		position: absolute;
		top: 12px;
		right: 55px;
		width: 32px;
		height: 32px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.04);

    @include HD {
      top: 16px;
      //right: 80px;
    }

		.unseen {
			position: absolute;
			bottom: -2px;
			right: -2px;
			width: 12px;
			height: 12px;
			color: #fff;
			text-align: center;
			font-size: 9px;
			line-height: 12px;
			text-decoration: none;
			border-radius: 50%;
			background-color: $secondaryColor;
		}
	}

  .logo {
    width: 70px;
    margin: 10px 0 0;

    svg {
      width: 100%;
      height: auto;
    }

    @include XGA {
      width: 70px;
    }
    
    @include HD {
      margin: 13px 0 0;
    }
      
  }
}

.header-info {
  z-index: 1999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $primaryColor;
  height: 48px;
  color: #fff;
  border-bottom: 1px solid #fff;
  font-size: 10px;
  text-align: center;
  padding: 9px;
  line-height: 1.3;

  span {
    display: none;
  }

  @include XGA {
    height: 40px;
    font-size: 14px;
    padding: 10px 0 8px;

    br {
      display: none;
    }

    span {
      display: inline;
    }
  }
}