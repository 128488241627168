@import "../../theme/variables.scss";

.Ranking {

  .rank-options {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    h1 {
      margin-bottom: 0;
    }

    .ranking-select {
      display: flex;

      .option {
        border: 1px solid $primaryColor;
        border-radius: 12px;
        margin-left: 12px;
        color: $primaryColor;
        padding: 6px 14px;
        height: 34px;
        cursor: pointer;

        &.active {
          background-color: $primaryColor;
          color: #fff;
          font-weight: 700;
        }
      }
    }
  }

  .rankign-head {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-bottom: 25px;
    padding-bottom: 15px;
    border-bottom: 1px solid $primaryColor;

    @include XGA {
      justify-content: flex-end; 
      margin-bottom: 35px;
    }

    span {
      font-size: 14px;
      font-weight: 700;
    }


    .DateSelect {
      display: flex;
      align-items: center;
      font-weight: 700;
      margin-left: 20px;

      .date-range {
        text-transform: capitalize;
      }

      .prev-date, .next-date {
        height: 28px;
        cursor: pointer;

        &.inactive {
          opacity: .3;
        }
      }
    }
  }

  .rankign-body {
    @include XGA {
      display: flex;
      justify-content: space-between;
      gap: 32px;
    }
  }

  .user-profile {
    background-color: #0C235C;
    min-width: 265px;
    height: 234px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 24px;
    gap: 12px;
    border-radius: 22px;
    margin-bottom: 12px;


    .user-name {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 14px;
      .profile-pic {
        width: 72px;
        height: 72px;
        border-radius: 50%;
      }
      .name {
        font-size: 22px;
        font-weight: 900;
      }
    }

    .user-place, .user-points {
      background: #FAB50D;
      border-radius: 14px;
      height: 45px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
      color: #001854;
      font-weight: 900;
    }

    .user-points {
      background: #F4F5F9;
    }
  }

  .user-ranking {
    @include XGA {
      width: 100%;
    }

    .user-ranking-row {
      display: flex;
      align-items: center;
      padding: 0 16px;
      height: 50px;
      font-size: 14px;
      color: $primaryColor;
      font-weight: 700;
      margin-bottom: 10px;
      border-bottom: 1px solid rgba(0, 24, 84, 0.2);

      @include XGA {
        height: 60px;
      }

      div:first-child {
        font-weight: 400;
        width: 50px;

        @include XGA {
          width: 45%;
        }
      }

      div:nth-child(2) {
        width: calc(50% - 15px);

        @include XGA {
          width: 30%;
        }
      }

      div:last-child {
        width: calc(50% - 15px);
        text-align: right;

        @include XGA {
          width: 25%;
        }
      }

      .name {
        display: flex;
        align-items: center;

        img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          object-fit: cover;
          margin-right: 10px;
        }
      }

      &:nth-child(2),&:nth-child(3),&:nth-child(4),&:nth-child(5),&:nth-child(6) {
        background-color: #F4F5F9;
        border-radius: 12px;
        border-bottom: 0;
      }
    }

    .orange {
      background-color: orange !important;
      border-radius: 12px;
      border-bottom: 0;
    }

    .user-ranking-head {
      height: 52px;
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 16px;

      div:first-child {
        text-indent: -9000px;

        @include XGA {
          text-indent: 0;
        }
      }

      .info-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
        position: relative;
      }

      .info-modal {
        display: none;
        position: absolute;
        top: 25px;
        width: 327px!important;
        text-align: center!important;
        background-color: #FFFFFF;
        padding: 12px 16px;
        box-shadow: 0px 5px 11px -3px rgb(66, 68, 90, 30%);
        border-radius: 12px;
        @include XGA {
          top: 50px;
        }

        h4 {
          margin: 0;
        }
      }
        
      .info-icon:hover + .info-modal {
          display: block;
      }
    }
  }
}