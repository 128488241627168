@import "../../theme/variables.scss";

.Login {
  max-width: 350px !important;

  @include XGA {
    padding-top: 40px !important;
    max-width: 1100px !important;
  }

  h1 {
    font-weight: 600;
    text-align: center;
  }

  input::placeholder {
    font-style: normal;
  }

  .password-input {
    position: relative;

    .show-pass {
      position: absolute;
      top: 43px;
      right: 15px;
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }
  
  .ticks {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 25px 0 35px;

    .tick {
      display: flex;
      align-items: center;
      font-size: 12px;
      padding-left: 38px;
      position: relative;
      width: 50%;
      margin-bottom: 10px;
      height: 20px;

      &:nth-child(even) {
        width: 120px;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        border: 1px solid #000;
      }

      &:after {
        content: "";
        position: absolute;
        top: 1px;
        left: 1px;
        width: 20px;
        height: 20px;
        background-image: url(../NewPass/tick.svg);
        background-position: center;
        background-repeat: no-repeat;
        display: none;
      }

      &.active {
        &:after {
          display: block;
        }
      }
    }
  }
}