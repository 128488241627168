@import "../../theme/variables.scss";

.Button {
  font-family: "Titillium Web", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;;
  height: 40px;
  width: 100%;
  max-width: 330px;
  margin: 0 auto;
  padding: 0 15px;
  background-color: $buttonColor;
  border: none;
  color: #000 ;
  text-align: center;
  font-weight: 700;
  outline: none;
  text-decoration: none;
  user-select: none;
  border-radius: 20px;
  cursor: pointer;
  transition: transform .1s $CubicEaseOut, background-color .3s $CubicEaseOut;

  &:hover {
    background-color: #ffca46;
  }

  &:active {
    transform: scale(0.95);
  }

  &.white {
    background: #fff;
    color: $primaryColor;
  }

  &.disabled {
    background-color: #929292;
  }
}