@import "../../theme/variables.scss";

.sales-outlets {
  .sales-nav-container {
    display: flex;
    height: 136px;
    position: relative;
    justify-content: space-between;
    
    @include XGA {
      flex-direction: row;
      height: auto;
      align-items: flex-start;
    }

    .search {
      position: absolute;
      top: 58px;
      left: 0px;
      height: 40px;
      border-radius: 14px;
      font-size: 14px;
      margin: 0;
      @include XGA {
        position: static;
        min-width: 280px;
      }
    }
    img {
      width: 33px;
      height: 33px;
      cursor: pointer;
    }
    .sales-nav {
      display: flex;
      flex-direction: row;
      gap: 12px;

      @include XGA {
        align-items: center;
      }
    }
  }

  .head-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    @include XGA {
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }

    .time-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
      
      @include XGA {
        flex-direction: row;
      }
    }
    .sales-head {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      @include XGA {
        justify-content: flex-end;
      }

      span {
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
  .sales-body {
    .grid {
      margin-top: 24px;
      @include XGA {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        //grid-template-rows: repeat(4, 1fr);
        grid-column-gap: 32px;
        grid-row-gap: 32px;
      }
    } 

    hr {
      display: block;
      border: 1px solid #0D245B;
      margin-top: 16px;
      @include XGA {
        display: none;
      }
    }

    .grid-box {
      background-color: #F4F5F9;
      border-radius: 12px;
      @include XGA {
        max-width: 250px;
      }
      .grid-box-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #FBB50D;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        padding: 0 24px;
        .header {
          font-weight: 700;
        }
      }
      .gird-box-body {
        .row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 24px;
          strong {
            padding-right: 10px;
          }
        }
      }
      .gird-box-footer {
        padding: 0 24px;
        p {
          border-top: 1px solid rgba(0, 24, 84, 0.2);
          padding-top: 12px;
          font-weight: 700;
          padding-bottom: 12px;
          @include XGA {
            padding-bottom: 0px;
          }

          span {
            display: block;
          }
        }
      }
    }

    .row-caption {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      color:#0C235C;
      grid-template-columns: [first] 92px [line2] 129px [line3] 42px [end];

      @include XGA {
        margin-top: 38px;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-columns: [first] 20% [line2] 15% [line3] 15% [col4-start] 15% [five] 30% [six] 5% [end];
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
      }
    }

    .row-caption-desktop {
      display: none;
      @include XGA {
        display: grid;
      }

      span {
        display: block;

        @include XGA {
          padding: 0 5% 0 20%;

          &:first-child {
            padding: 0;
          }

          &:nth-child(5) {
            padding: 0 5% 0 8%;
          }
        }
      }
    }

    .row-caption-mobile {
      display: grid;
      margin-top: 16px;
      @include XGA {
        display: none;
      }

      span {

        &:nth-child(2) {
          padding-left: 10px;
        }
      }
    }

    .row-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-columns: [first] 92px [line2] 129px [line3] 42px [end];
      grid-template-rows: 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      font-weight: 700;
      height: 64px;
      margin-top: 12px;
      background-color: #F4F5F9;
      border-radius: 12px;
      margin-bottom: 60px;
      
      @include XGA {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-columns: [first] 20% [line2] 15% [line3] 15% [col4-start] 15% [five] 30% [six] 5% [end];
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        margin-bottom: 0;
      }

      p {
        @include XGA {
          font-size: 18px;
          padding: 0 5% 0 20%;
        }
      }

      .adress {
        font-size: 10px;
        padding-left: 10px;
        @include XGA {
          font-size: 14px;
          padding-left: 8%;
          padding-right: 0;
        }

        span {
          display: block;
        }
      }

      .header {
        min-width: 124px;
        text-align: center;
        background-color: #FBB50D;
        margin: 0;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @include XGA {
          font-size: 20px;
          //min-width: 200px;
        }
      }
      position: relative;
      .drop-down {
        bottom: -24px;
        position: absolute;
        text-align: center;
        width: 100%;
        display: block;
      }

      .drop-down-btn {
        span {
          display: flex;
          align-items: center;
          gap: 2px;
          justify-content: center;
          font-size: 12px;
          margin: 0;
          cursor: pointer;
        }
      }

      .drop-down-active {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          p {
            font-size: 10px;
            font-weight: 400;
            margin: 0;
          }

          .strong {
            font-size: 10px;
            font-weight: 700;
          }
        }
      }
    }
  }


  .DateSelect {
    display: flex;
    align-items: center;
    font-weight: 700;
    
    .date-range {
      text-transform: capitalize;
    }

    @include XGA {
      margin-left: 20px;
    }

    .prev-date, .next-date {
      height: 28px;
      cursor: pointer;
    }
  }
}