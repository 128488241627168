@import "../../../theme/variables.scss";

.NewsDetails {
  position: relative;
  width: 90%;
  margin: 0 5%;
  max-width: 1170px;

  @include XGA {
    margin: 0 auto;
  }

  .news-wrap {
    @include XGA {
      display: flex;
      justify-content: space-between;
    }
  }

  .desktop-news {
    display: none;
    padding-bottom: 100px;

    .news-body {
      margin-top: 10px;
    }

    .news-body > .news-desc {
      text-align: center;
      margin: 25px 0;
    }

    .news-body > .cta {
      margin: 0 auto;
    }

    .news-body > .news-table p {
      width: 80%;
      margin: 0 auto;

      @include HD {
        width: 60%;
        line-height: 25px;
      }
    }

    .news-heading {
      display: flex;
      flex-direction: column;

      .calendar {
        width: 100%;
        transform: translateY(-20px);
        display: flex;

        .date {
          font-size: 10px;
          color: #001854;
          margin: 0 5px;
          width: 60px;
          line-height: 10px;

          &::before {
            content: "";
            position: absolute;
            left: 70px;
            background: #001854;
            height: 1px;
            display: block;
            width: 60%;
            bottom: 0;
            transform: translateY(-100%);
          }
        }
      }
    }

    .news-body > .news-table {
      padding: 13px;
      border-radius: 12px;
      border: 1px solid #c3c9d8;
    }
    .news-table p {
      width: 100%;
    }
    .news-body > p {
      padding: 0 10%;
    }

    .news-table > p {
      margin: 2px 0;
    }
    .news-title {
      text-align: left;
      font-size: 39px;
      line-height: 42px;
      font-weight: 600;
      margin-top: 0;
    }
    .news-subtitle {
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      font-weight: 600;
    }

    @include XGA {
      display: block;

      .cta {
        margin: 0 auto;
        max-width: 225px;
      }
    }
    @include HD {
      width: 468px;
      top: 100px;
    }
    .news-body {
      border-radius: 12px;
      background: #fff;
      padding: 32px 14px;
    }
  }

  .news-item {
    list-style: none;
    position: relative;

    &.fixed {
    
        @include XGA {
          position: fixed;
          top: 100px;
        }
        @include XGA {
          top: 140px;
        }
      
    }

    strong {
      font-weight: 600;
    }

    @include XGA {
      margin-bottom: 25px;
      width: 468px;
      height: 293px;
    }

    @include HD {
      margin-bottom: 25px;
      width: 568px;
      height: 393px;
    }

    .news-item-category {
      position: absolute;
      right: 5%;
      top: 0;
      transform: translateY(-50%);
      background: $pink;
      border-radius: 100px;
      min-width: 82px;
      min-height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      padding: 0 10px;
      font-size: 8px;
      font-weight: 300;

      &.contest {
        background: $tertiaryColor;
      }
      @include XGA {
        font-size: 14px;
        min-height: 33px;
        min-width: 150px;
      }
    }

    .news-image {
      height: 100%;
      width: 100%;
      border-radius: 12px;
      overflow: hidden;
      height: 209px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      @include XGA {
        height: 393px;
      }
    }

    .news-body {
      padding: 32px 14px;
      max-width: 90%;
      margin: 0 auto;
      background: #fff;
      border-radius: 12px;
      transform: translateY(-50px);
      &.mobile {
        display: block;

        @include XGA {
          display: none;
        }
      }
    }

    .module {
      p {
        margin: 25px 0;
      }
    }

    .news-body > .news-desc {
      text-align: center;
      margin: 25px 0;
    }

    .news-body > .cta {
      margin: 0 auto;
    }

    .news-body > .news-table {
      padding: 13px;
      border-radius: 12px;
      border: 1px solid #c3c9d8;
    }
    .news-table p {
      width: 100%;
    }
    .news-body > p {
      padding: 0 10%;
    }

    .news-table > p {
      margin: 2px 0;
    }
    .news-title {
      text-align: center;
      font-size: 23px;
      line-height: 28px;
      font-weight: 600;
      margin-top: 0;
    }
  }
}
